import { useQuery, useMutation } from "@tanstack/react-query";
import { subDays, isBefore } from "date-fns";
import React, { useState, useEffect, useRef } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import {
  RouteComponentProps,
  withRouter,
  NavLink,
  useHistory,
} from "react-router-dom";

import CollectionChartArea from "../../components/AnalysisCollectionsComponents/CollectionChartArea";
import { ModalCreateEditChart } from "../../components/AnalysisCollectionsComponents/ModalCreateEditChart";
import { ImpersonateOnly } from "../../components/Common/ImpersonateOnly";
import RecentPostList, {
  RecentMediaListContext,
} from "../../components/DashboardComponents/RecentPostsList";
import { BasicChecklist } from "../../components/FacilityDetailsComponents/BasicChecklist";
import { BasicProfile } from "../../components/FacilityDetailsComponents/BasicProfile";
import ExternalIntegrations from "../../components/FacilityDetailsComponents/ExternalIntegrations";
import { ImportantMetrics } from "../../components/FacilityDetailsComponents/ImportantMetrics";
import { ModalCreateLocalPost } from "../../components/FacilityDetailsComponents/ModalCreateLocalPost";
import ModalUnRepliedReviews from "../../components/FacilityDetailsComponents/ModalUnRepliedReviews";
import { PickupFields } from "../../components/FacilityDetailsComponents/PickupFields";
import { ReviewSummary } from "../../components/FacilityDetailsComponents/ReviewSummary";
import { Suggestions } from "../../components/FacilityDetailsComponents/Suggestions";
import { Summary } from "../../components/FacilityDetailsComponents/Summary";
import { InternalToolContainer } from "../../components/InternalTools/InternalToolsContainer";
import { InternalToolsContextProvider } from "../../components/InternalTools/InternalToolsContextProvider";
import { InternalToolEnum } from "../../components/InternalTools/InternalToolsDictionaryAndTypes";
import { ModalConfirm } from "../../components/ModalConfirm";
import { ScoreItem } from "../../components/ScoreItem";
import { useMe } from "../../hooks/queries";
import { canAccessOrganization } from "../../hooks/validateUserOrganization";
import { AccountType } from "../../models/AccountData";
import { ChartData } from "../../models/ChartCollectionData";
import { OrgFeatures } from "../../models/FeatureUseLimitsData";
import { LabelData } from "../../models/LabelData";
import { LocalPostData } from "../../models/LocalPostData";
import DataSvc from "../../services/dataSvc";
import { percentage } from "../../utils/common";
import SendMessageScreens from "../SendMessageScreens";

import "./styles.scss";

interface MatchParams {
  locationId?: string;
  organizationId?: string;
}

type IFacilityDetailsPageProps = RouteComponentProps<MatchParams>;

const FacilityDetailsPage: React.FunctionComponent<
  IFacilityDetailsPageProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`facilityDetailsPage.${key}`);

  const { data: me } = useMe();
  const history = useHistory();
  const internalToolsRef = useRef<HTMLDivElement>(null);

  const locationId = props.match.params.locationId ?? "";
  const organizationId = props.match.params.organizationId ?? "";

  const [showSendMessageScreens, setShowSendMessageScreens] = useState(false);

  const [shownModalCreateLocalPost, setShownModalCreateLocalPost] =
    useState<boolean>(false);
  const [shownModalConfirm, setShownModalConfirm] = useState<boolean>(false);
  const [showUnRepliedReviews, setShowUnRepliedReviews] =
    useState<boolean>(false);
  const [
    showDeleteInstagramIntegrationConfirm,
    setShowDeleteInstagramIntegrationConfirm,
  ] = useState<boolean>(false);

  const [indexLabel, setIndexLabel] = useState<number>(-1);

  const [shownModalCreateEditChart, setShownModalCreateEditChart] =
    useState<boolean>(false);
  const [currentChartData, setCurrentChartData] =
    useState<ChartData>(DEFAULT_CHART_DATA);
  const [recentLocalPostList, setRecentLocalPostList] = useState<
    LocalPostData[]
  >([]);

  const { data: locationData, refetch: refetchLocationData } = useQuery(
    ["location", locationId],
    async () => {
      return await DataSvc.getLocation(locationId);
    }
  );

  // get the latest recommendation history
  const { data: latestRecommendationHistory } = useQuery(
    ["latest-recommendation-history", locationId],
    async () => {
      return await DataSvc.getLocationRecommendationHistory(
        organizationId,
        locationId,
        1
      );
    }
  );

  // get latest recommendations for the location
  const { data: locationRecommendations } = useQuery(
    ["location-recommendations", locationId, latestRecommendationHistory],
    () => {
      if (latestRecommendationHistory?.histories?.length > 0) {
        const lastGeneratedDate =
          latestRecommendationHistory?.histories[0].generated_date;
        if (lastGeneratedDate) {
          const oneWeekBefore = subDays(new Date(), 8); // Use 8 for timezone difference
          if (isBefore(oneWeekBefore, new Date(lastGeneratedDate))) {
            return DataSvc.getRecommendations(
              {
                location_id: locationId,
                generated_date: lastGeneratedDate,
              },
              false
            );
          }
        }
      }
      return [];
    }
  );

  const deleteLabelMutation = useMutation((payload: any) => {
    return DataSvc.deleteLocationLabel(payload.locationId, payload.labelId);
  });

  // on Delete Label
  const onDeleteLabel = (indexLabel: number) => {
    if (locationData) {
      deleteLabelMutation
        .mutateAsync({
          locationId,
          labelId: locationData.labels[indexLabel].id,
        })
        .then(() => {
          locationData.labels.splice(indexLabel, 1);
        });
    }
  };

  const addLabelMutation = useMutation((payload: any) => {
    return DataSvc.addLocationLabel(payload.locationId, payload.labelId);
  });

  const { data: localPostList } = useQuery(
    ["location.localPosts", locationId],
    () => {
      if (locationId) {
        return DataSvc.getLocalPosts({
          limit: 10,
          offset: 0,
          order: "desc",
          sort: "post_date",
          location_id: [locationId],
        });
      }
      return null;
    }
  );

  useEffect(() => {
    if (!me || !locationId) {
      return;
    }

    if (me.type === AccountType.LOC_ADMIN && !!organizationId) {
      history.push(`/location/${locationId}`);
    } else if (
      me.type === AccountType.ORG_ADMIN &&
      !canAccessOrganization(organizationId, me)
    ) {
      history.push(
        `/location/${locationId}/organization/${me.organization.id}`
      );
    }
  }, [locationId, organizationId, me, history]);

  useEffect(() => {
    if (localPostList) {
      setRecentLocalPostList(localPostList);
    }
  }, [localPostList]);

  // on Add Label
  const onAddLabel = (label: LabelData) => {
    if (locationData) {
      addLabelMutation
        .mutateAsync({
          locationId,
          labelId: label.id,
        })
        .then(() => {
          locationData.labels.push(label);
        });
    }
  };

  const deleteInstagramIntegrationMutation = useMutation((payload: any) => {
    return DataSvc.deleteInstagramIntegration(
      payload.locationId,
      payload.userId
    );
  });

  const deleteInstagramIntegration = () => {
    if (locationData) {
      deleteInstagramIntegrationMutation
        .mutateAsync({
          locationId,
          userId: `${me?.id}`,
        })
        .then(() => {
          locationData.locations_instagram_integrations = [];
        });
    }
  };

  // get Profile Views Change ratio
  const getProfileViewsChangeRatio = (): number => {
    if (locationData) {
      return (
        ((locationData.stat.profile_views -
          locationData.stat.prev_profile_views) /
          locationData.stat.prev_profile_views) *
        100
      );
    } else {
      return 0;
    }
  };

  const onChooseChart = (selectedTemplateChart: ChartData) => {
    setCurrentChartData(selectedTemplateChart);
    setShownModalCreateEditChart(false);

    ReactGA.event({
      category: "facility_details",
      action: "See Past Location Data",
      label: selectedTemplateChart.template_name,
    });
  };

  const gbpLocIdNum =
    locationData?.gbp_location_id.replace("locations/", "") || undefined;

  const isOrgUser = () => {
    return me?.type !== AccountType.LOC_ADMIN;
  };

  const internalToolsInUse = [
    InternalToolEnum.GetReviews,
    InternalToolEnum.MetricsNew,
  ];

  return (
    <>
      <InternalToolsContextProvider
        defaultTools={internalToolsInUse}
        toolsToExpose={internalToolsInUse}
      >
        <div className="right-content facility-details">
          <div className="back-top-bar flex-grid">
            <NavLink
              to={`/locations${
                organizationId ? `/organization/${organizationId}` : ""
              }`}
              className="back-link"
            >
              <i className="icons icon-back"></i>
              {t("back_to_list")}
            </NavLink>
            <div className="right-btns">
              {/* TODO: 
                // Future feature to print screen as a pdf
                <a href="#javascript" className='btn btn-border'>
                  <i className='icons icon-pdf'></i>
                  PDF
                </a>
                */}
              {isOrgUser() &&
                locationData &&
                (locationData.has_loc_admins ? (
                  <button
                    onClick={() => {
                      setShowSendMessageScreens(true);
                    }}
                    className="btn btn-blue"
                  >
                    {t("to_send_message_to_fac")}
                  </button>
                ) : (
                  <div className="btn btn-blue btn-blue--fade">
                    {t("to_send_message_to_fac")}
                    <div className="pink-tips">
                      {t("cannot_send_because_no_loc_admin")}
                    </div>
                  </div>
                ))}
              {!!gbpLocIdNum && (
                <div className="to-gbp-button">
                  <a
                    href={`https://business.google.com/n/${gbpLocIdNum}/profile`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-blue"
                    onClick={() => {
                      ReactGA.event({
                        category: "GBP Link",
                        action: "Click to GBP",
                        label: "main",
                      });
                    }}
                  >
                    {t("to_facilitys_gbp_profile")}
                  </a>
                  <small className="footnote">
                    {t("basicChecklist.may_require_gbp_login")}
                  </small>
                </div>
              )}
            </div>
          </div>
          {
            // TODO:
            !!locationData && (
              <div className="flex-grid">
                <div className="big-bold-title">{locationData.name}</div>
                <div className="rights">
                  <div className="big-bold-title">
                    {t("health_index")}
                    <ScoreItem score={locationData.stat.score} />
                  </div>
                </div>
              </div>
            )
          }
          {!!locationData && (
            <>
              <div className="detail-group">
                <div className="group-title">
                  {t("basic_profile")}
                  <div className="line"></div>
                </div>
                <BasicProfile
                  organizationId={organizationId}
                  locationData={locationData}
                  onClickReviewCounts={() => {
                    internalToolsRef.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                  onDeleteLabel={(indexLabel: number, hasConfirm: boolean) => {
                    if (hasConfirm) {
                      setIndexLabel(indexLabel);
                      setShownModalConfirm(true);
                    } else {
                      onDeleteLabel(indexLabel);
                    }
                  }}
                  onAddLabel={(label: LabelData) => {
                    onAddLabel(label);
                  }}
                />
              </div>

              {!(
                isOrgUser() &&
                !me?.organization.org_feature_use_limit.some(
                  (item) =>
                    item.org_feature.name === OrgFeatures.USE_LOCAL_POST &&
                    item.can_use
                )
              ) && (
                <div className="detail-group">
                  <div className="group-title">
                    {t("external_integrations")}
                    <div className="line"></div>
                  </div>
                  <ExternalIntegrations
                    locationData={locationData}
                    onDisconnectInstagram={() => {
                      setShowDeleteInstagramIntegrationConfirm(true);
                    }}
                  />
                </div>
              )}

              <div className="detail-group">
                <div className="group-title">
                  {t("report")}
                  <div className="line"></div>
                </div>

                <Summary
                  statsPrime={locationData}
                  firstSentenceValue={
                    locationData ? locationData.stat?.score : ""
                  }
                  secondSentenceValue={
                    locationData ? percentage(getProfileViewsChangeRatio()) : 0
                  }
                  pageType="FacilityDetails"
                />

                {!!locationRecommendations && (
                  <Suggestions
                    buttonType={"link"}
                    shownAll={false}
                    recommendations={locationRecommendations}
                  />
                )}
                <ImpersonateOnly>
                  <ReviewSummary locationData={locationData} />
                </ImpersonateOnly>
                <div className="two-panel">
                  <div className="row">
                    <div className="col col-md-5">
                      <BasicChecklist
                        onOpenModalUnRepliedReviews={() =>
                          setShowUnRepliedReviews(true)
                        }
                        locationData={locationData}
                        onClickCreatePostToGoogleBusinessProfile={() =>
                          setShownModalCreateLocalPost(true)
                        }
                      />
                    </div>
                    <div className="col col-md-7">
                      <ImportantMetrics locationData={locationData} />
                    </div>
                  </div>
                </div>
                <PickupFields locationData={locationData} />
              </div>
              <div className="detail-group">
                <div className="group-title">
                  {t("recent_local_posts_of_facility")}
                  <div className="line"></div>
                </div>
                <small className="status-hint">
                  {t("differs_from_actual_status")}
                </small>
                <RecentPostList
                  dataList={recentLocalPostList}
                  isShowLocationName={false}
                  context={RecentMediaListContext.OWN_LOCATION_HISTORY}
                />
              </div>
              <div className="detail-group">
                <div className="group-title">
                  {t("past_data")}
                  <div className="line"></div>
                </div>
                <div className="white-border-panel analytics-module padding32">
                  {currentChartData.id !== 0 ? (
                    <CollectionChartArea
                      indivFacilityMode={true}
                      chartData={currentChartData}
                      totalLength={1}
                      chartIndex={1}
                      onClickEditChart={() =>
                        setShownModalCreateEditChart(true)
                      }
                      onClickCopyChart={() => {}}
                      onClickMoveUp={() => {}}
                      onClickMoveDown={() => {}}
                      onUpdate={() => {}}
                    />
                  ) : (
                    <div className="add-chart">
                      <button
                        className="btn btn-border"
                        onClick={() => {
                          setShownModalCreateEditChart(true);
                        }}
                      >
                        <i className="icons icon-add"></i>
                        {t("set_chart")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="facility-page-internal-tools"
                ref={internalToolsRef}
              >
                <InternalToolContainer
                  initialGoogleInformation={undefined}
                  locationData={locationData}
                  overlayTextKey="facilityDetailsPage.dataLinkExpired"
                  disableToolContainer={locationData?.is_archived}
                />
              </div>
            </>
          )}
        </div>
      </InternalToolsContextProvider>
      {shownModalConfirm && (
        <ModalConfirm
          title={"please_confirm_to_remove_the_label"}
          cancelLabel={"cancel"}
          confirmLabel={"confirm"}
          onClose={() => {
            setShownModalConfirm(false);
          }}
          onConfirm={() => {
            onDeleteLabel(indexLabel);
            setShownModalConfirm(false);
          }}
        />
      )}
      {locationData && showSendMessageScreens && (
        <SendMessageScreens
          locationData={locationData}
          setShowSendMessageScreens={setShowSendMessageScreens}
        />
      )}

      {
        // Facility Details do not have an edit mode
        shownModalCreateEditChart && (
          <ModalCreateEditChart
            indivFacilityMode={true}
            isEdit={false}
            editingChartData={currentChartData}
            onClose={() => {
              setShownModalCreateEditChart(false);
            }}
            onDelete={() => {}}
            onCreateSaveChart={(
              labelName: string,
              selectedTemplateChart: ChartData
            ) => {
              onChooseChart({
                ...selectedTemplateChart,
                location_id: Number(locationId),
              });
            }}
          />
        )
      }

      {locationData && shownModalCreateLocalPost && (
        <ModalCreateLocalPost
          locationData={locationData}
          locationId={locationId}
          onClose={() => {
            setShownModalCreateLocalPost(false);
          }}
          refetch={refetchLocationData}
        />
      )}
      {showUnRepliedReviews && (
        <ModalUnRepliedReviews
          locationId={locationId}
          onClose={() => setShowUnRepliedReviews(false)}
        />
      )}
      {showDeleteInstagramIntegrationConfirm && (
        <ModalConfirm
          title={"please_confirm_to_disconnect_instagram"}
          cancelLabel={"cancel"}
          confirmLabel={"remove"}
          onClose={() => {
            setShowDeleteInstagramIntegrationConfirm(false);
          }}
          onConfirm={() => {
            deleteInstagramIntegration();
            setShowDeleteInstagramIntegrationConfirm(false);
          }}
        />
      )}
    </>
  );
};

const DEFAULT_CHART_DATA: ChartData = {
  name: "",
  collection_id: 0,
  template_id: 0,
  template_name: "",
  period_start: "",
  period_end: "",
  period_option: "custom",
  type: "bar",
  compare_to_prev: false,
  time_unit: "day",
  metrics: "",
  aggregation: "sum",
  grouping1: "",
  grouping2: "",
  prefecture_filter: "",
  municipality_filter: "",
  category_filter: "",
  label_filter: "",
  display_order: 0,
  is_template: true,
  id: 0,
  created_at: "",
  created_by: 1,
  last_modified_at: "",
  last_modified_by: 1,
};

export default withRouter(FacilityDetailsPage);
