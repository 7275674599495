/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery, useMutation } from "@tanstack/react-query";
import _ from "lodash";
import { stringify } from "qs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter, useHistory } from "react-router-dom";

import { FacilityList } from "../../components/FacilityListComponents/FacilityList";
import { ModalFilterCondition } from "../../components/FacilityListComponents/ModalFilterCondition";
import { ModalConfirm } from "../../components/ModalConfirm";
import { FACILITY_SORT_BY, ORDER, FACILITY_SORT } from "../../config";
import {
  useLabels,
  useCategories,
  useSearchQuery,
  useMe,
  useLocationMunis,
} from "../../hooks/queries";
import { canAccessOrganization } from "../../hooks/validateUserOrganization";
import {
  FacilityFilterConditionData,
  createDefaultConditionFormData,
  getScoreArrayString,
  getProfBoolQueryObject,
} from "../../models/FacilityFilterConditionData";
import { LabelData } from "../../models/LabelData";
import { LocationData } from "../../models/LocationData";
import {
  MunicipalityGroupData,
  convertToGroupData,
} from "../../models/MunicipalityData";
import DataSvc from "../../services/dataSvc";
import {
  convertFormDataToQueryParams,
  convertSearchQueryToConditionFormData,
} from "../../utils/conditionFormDataHelpers";

import "./styles.scss";

const defaultFilterFormData = {
  searchName: "",
  sort: FACILITY_SORT.STATS_PROFILE_VIEWS,
  order: ORDER.DESC,
  sortBy: FACILITY_SORT_BY.PROFILE_VIEWS_DESC,
  numberPerPage: 20,
  pageIndex: 1,
};

interface IFilterFormData {
  searchName: string;
  sort: typeof FACILITY_SORT;
  order: typeof ORDER;
  sortBy: typeof FACILITY_SORT_BY;
  numberPerPage: number;
  pageIndex: number;
}

const defaultConditionFormData = createDefaultConditionFormData();

type IFacilityListPageProps = RouteComponentProps<any>;

const FacilityListPage: React.FunctionComponent<IFacilityListPageProps> = ({
  match,
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`facilityListPage.${key}`);

  const history = useHistory();
  const configData = JSON.parse(
    sessionStorage.getItem("FacilityListPage_Config") || "{}"
  );
  const searchQuery = useSearchQuery();

  useEffect(() => {
    if (
      !_.isEmpty(configData?.searchQuery) &&
      _.isEmpty(searchQuery) &&
      !_.isEqual(configData.searchQuery, searchQuery)
    ) {
      history.replace({
        search: stringify(configData.searchQuery, { arrayFormat: "repeat" }),
      });
    }
  }, []);

  const [locationsData, setLocationsData] = useState<LocationData[]>([]);
  const [totalLocations, setTotalLocations] = useState(0);
  const [shownModalFilterCondition, setShownModalFilterCondition] =
    useState<boolean>(false); // false
  const [shownModalConfirm, setShownModalConfirm] = useState<boolean>(false); // false
  const [organizationId, setOrganizationId] = useState();

  const [indexFacility, setIndexFacility] = useState<number>(-1);
  const [indexLabel, setIndexLabel] = useState<number>(-1);

  const [conditionFormData, setConditionFormData] =
    useState<FacilityFilterConditionData>(defaultConditionFormData);
  const [filterFormData, setFilterFormData] = useState<IFilterFormData>(
    configData?.filterFormData || defaultFilterFormData
  );

  const [municipalitiesData, setMunicipalitiesData] = useState<
    MunicipalityGroupData[]
  >([]);
  const { data: me } = useMe();

  const { data: locations } = useQuery(
    ["locations", organizationId, searchQuery, filterFormData],
    () => {
      sessionStorage.setItem(
        "FacilityListPage_Config",
        JSON.stringify({ filterFormData, searchQuery })
      );
      return DataSvc.getLocations(
        _.pickBy(
          {
            sort: filterFormData.sort,
            order: filterFormData.order,
            limit: filterFormData.numberPerPage,
            offset:
              (filterFormData.pageIndex - 1) * filterFormData.numberPerPage,
            organization_id: organizationId ? [organizationId] : organizationId,
            seek_total_count: true,
            label_id: searchQuery.label_id
              ? _.concat([], searchQuery.label_id)
              : null,
            category_id: searchQuery.category_id
              ? _.concat([], searchQuery.category_id)
              : null,
            municipality_id: searchQuery.municipality_id
              ? _.concat([], searchQuery.municipality_id)
              : null,
            query: filterFormData.searchName.trim(),
            score: getScoreArrayString(conditionFormData),
            ...getProfBoolQueryObject(conditionFormData),
            ...(searchQuery.has_lodging
              ? { has_lodging: searchQuery.has_lodging === "true" }
              : {}),
          },
          (value: any) => {
            return !_.isUndefined(value) && !_.isNull(value);
          }
        )
      );
    }
  );

  const { data: labels } = useLabels(
    _.pickBy({
      organization_id: organizationId,
    })
  );
  const { data: categories } = useCategories();
  const { data: municipalities } = useLocationMunis(
    me?.id,
    organizationId || String(match.params.organizationId ?? "")
  );

  useEffect(() => {
    if (municipalities) {
      const municipalitiesArray = convertToGroupData(municipalities);
      setMunicipalitiesData(municipalitiesArray);
    }
  }, [municipalities]);

  useEffect(() => {
    if (locations) {
      setLocationsData(locations.list);
      setTotalLocations(locations.total);
    }
  }, [locations]);

  useEffect(() => {
    if (!me) {
      return;
    }

    const userOrgId = me.organization?.id;
    if (
      match.params.organizationId &&
      canAccessOrganization(match.params.organizationId, me)
    ) {
      setOrganizationId(match.params.organizationId);
    } else if (userOrgId) {
      history.push(`/locations/organization/${userOrgId}`);
    } else if (!userOrgId && match.params.organizationId) {
      history.push(`/locations/`);
    }
  }, [match.params.organizationId, me]);

  useEffect(() => {
    const formData = convertSearchQueryToConditionFormData(
      searchQuery,
      municipalitiesData,
      categories,
      labels
    );
    setConditionFormData(formData);
  }, [municipalitiesData, labels, categories, searchQuery]);

  // on Apply Filter Condition
  const onApplyFilterCondition = (
    conditionFormDataApply: FacilityFilterConditionData
  ) => {
    const queryParams = convertFormDataToQueryParams(conditionFormDataApply);
    sessionStorage.setItem(
      "FacilityListPage_Config",
      JSON.stringify({ filterFormData, searchQuery: queryParams })
    );
    history.replace({
      search: stringify(queryParams, { arrayFormat: "repeat" }),
    });

    setShownModalFilterCondition(false);
  };

  // on Click Clear Filter Condition
  const onClickClearFilterCondition = () => {
    sessionStorage.setItem("FacilityListPage_Config", "{}");
    history.replace({
      search: "",
    });
  };

  const deleteLabelMutation = useMutation((payload: any) => {
    return DataSvc.deleteLocationLabel(payload.locationId, payload.labelId);
  });

  // on Delete Label
  const onDeleteLabel = (indexFacility: number, indexLabel: number) => {
    if (locationsData) {
      deleteLabelMutation
        .mutateAsync({
          locationId: locationsData[indexFacility].id,
          labelId: locationsData[indexFacility].labels[indexLabel].id,
        })
        .then(() => {
          const locationsDataTemp = locationsData;
          locationsDataTemp[indexFacility].labels.splice(indexLabel, 1);
          setLocationsData(_.cloneDeep(locationsDataTemp));
        });
    }
  };

  const addLabelMutation = useMutation((payload: any) => {
    return DataSvc.addLocationLabel(payload.locationId, payload.labelId);
  });

  // on Add Label
  const onAddLabel = (indexFacility: number, label: LabelData) => {
    if (locationsData) {
      addLabelMutation
        .mutateAsync({
          locationId: locationsData[indexFacility].id,
          labelId: label.id,
        })
        .then(() => {
          const locationsDataTemp = locationsData;
          locationsDataTemp[indexFacility].labels.push(label);
          setLocationsData(_.cloneDeep(locationsDataTemp));
        });
    }
  };

  return (
    <>
      <div className="right-content facility-list">
        <div className="top-title-bar flex-grid">
          <div className="left-title">{t("facilities")}</div>
          {/* TODO:
              <div className="rights">
                <a href="#javascript" className="btn btn-border">
                  <i className="icons icon-pdf"></i>
                  PDF
                </a>
                <a href="#javascript" className="btn btn-border">
                  <i className="icons icon-csv"></i>
                  CSV
                </a>
              </div>
              */}
        </div>
        {!!locationsData && (
          <FacilityList
            dataList={locationsData}
            totalCount={totalLocations}
            conditionFormData={conditionFormData}
            filterFormData={filterFormData}
            organizationId={organizationId || ""}
            onClickFilter={() => {
              setShownModalFilterCondition(true);
            }}
            onClickClearFilterCondition={() => {
              onClickClearFilterCondition();
            }}
            onDeleteLabel={(
              indexFacility: number,
              indexLabel: number,
              hasConfirm: boolean
            ) => {
              if (hasConfirm) {
                setIndexFacility(indexFacility);
                setIndexLabel(indexLabel);
                setShownModalConfirm(true);
              } else {
                onDeleteLabel(indexFacility, indexLabel);
              }
            }}
            onAddLabel={(indexFacility: number, label: LabelData) => {
              onAddLabel(indexFacility, label);
            }}
            onChangeFilterFormData={(filterFormData: any) => {
              setFilterFormData(filterFormData);
            }}
          />
        )}
      </div>
      {shownModalFilterCondition && (
        <ModalFilterCondition
          organizationId={organizationId || ""}
          userId={me?.id}
          onClose={() => {
            setShownModalFilterCondition(false);
          }}
          conditionFormData={conditionFormData}
          onApply={(conditionFormDataApply: FacilityFilterConditionData) => {
            onApplyFilterCondition(conditionFormDataApply);
          }}
        />
      )}

      {shownModalConfirm && (
        <ModalConfirm
          title={"please_confirm_to_remove_the_label"}
          cancelLabel={"cancel"}
          confirmLabel={"confirm"}
          onClose={() => {
            setShownModalConfirm(false);
          }}
          onConfirm={() => {
            onDeleteLabel(indexFacility, indexLabel);
            setShownModalConfirm(false);
          }}
        />
      )}
    </>
  );
};

export default withRouter(FacilityListPage);
