import React, { useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { LocationData } from "../../../models/LocationData";
import DataSvc from "../../../services/dataSvc";
import { createNestedFormData } from "../../../utils/formData";
import { ModalPleaseWait } from "../../ModalPleaseWait";
import { CreateLocalPostForm } from "../CreateLocalPostForm";
import { CreateLocalPostState } from "../CreateLocalPostForm/utils";
import { ModalLocalPostIdeas } from "../ModalLocalPostIdeas";

import "./styles.scss";

export interface IModalCreateLocalPostProps {
  locationData: LocationData;
  locationId: string;
  refetch: () => void;
  onClose: () => void;
}

export const ModalCreateLocalPost: React.FunctionComponent<
  IModalCreateLocalPostProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`analysisCollectionsPage.modalCreateLocalPost.${key}`);

  const { locationData, onClose, locationId, refetch } = props;

  const [shownModalLocalPostIdeas, setShownModalLocalPostIdeas] =
    useState<boolean>(false);

  const [shownModalPleaseWait, setShownModalPleaseWait] =
    useState<boolean>(false);

  const post = async (value: CreateLocalPostState) => {
    const { media, ...data } = value;

    const formData = createNestedFormData(data);

    media.forEach((_media) => {
      formData.append("media", _media);
    });

    ReactGA.event("Local Post Posted", {
      category: "create-local-post",
      label: data.topicType,
      hasMedia: media.length > 0,
      hasText: data.text.length > 0,
      callToActionType: data.callToAction.actionType,
    });

    setShownModalPleaseWait(true);
    try {
      const response = await DataSvc.createLocalPost(locationId, formData);

      if (!data.postToInstagram) {
        toast.success(t("gbp_success"));
      } else {
        const isInstagramFailed = response.data.instagramPost === undefined;
        if (isInstagramFailed) {
          toast.success(t("gbp_success"));
          toast.error(t("instagram_failure"));
        } else {
          toast.success(t("all_success"));
        }
      }

      refetch();
      onClose();
    } catch (error) {
      // Do nothing. Axios should handle displaying the error automatically.
    } finally {
      setShownModalPleaseWait(false);
    }
  };

  return (
    <div className="modal modal-default modal-create-local-post">
      <div className="modal-mains">
        <button
          className="btn-close"
          onClick={() => {
            onClose();
          }}
        ></button>
        <div className="modal-mains__body">
          <div className="top-title">
            <h2>{t("new_post")}</h2>
            {false && (
              <button
                className="btn btn-border"
                onClick={() => setShownModalLocalPostIdeas(true)}
              >
                {t("get_ideas")}
              </button>
            )}
          </div>
          <CreateLocalPostForm
            locationData={locationData}
            post={post}
            onClose={onClose}
            canGenerateAiText
            enablePostToInstagram
            locationNames={[locationData.name]}
          />
        </div>
      </div>
      {shownModalLocalPostIdeas && (
        <ModalLocalPostIdeas
          locationId={locationId}
          onClose={() => setShownModalLocalPostIdeas(false)}
        />
      )}
      {shownModalPleaseWait && (
        <ModalPleaseWait description={t("please_wait_message")} />
      )}
    </div>
  );
};
